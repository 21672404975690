<template>
    <div>
        <b-modal title="Remove account from whitelist" ref="removeModal">
            <p> {{ 'Are you sure you want to remove this account from the whitelist?' }} </p>
            <template #footer>
                <div class="text-end">
                    <ui-button variant="danger" @click="remove" :loading="loading">
                        Remove
                    </ui-button>
                </div>
                <ui-button variant="secondary" @click="closeModal"> Cancel </ui-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button';

export default {
    name: 'RemoveModal',
    components: { UiButton },
    data() {
        return {
            loading: false,
            account_id: '',
        }
    },
    methods: {
        openModal(account_id) {
            this.account_id = account_id;
            this.$refs.removeModal.show();
        },
        closeModal() {
            this.$refs.removeModal.hide();
        },
        async remove() {
            try {
                this.loading = true;
                await this.$axios.delete(`/_/whitelist/remove/${this.account_id}`);
                this.$emit('removed');
                this.$toast.success('Removed account from whitelist successfully');
            } catch (err) {
                this.$toast.error(this.getErrorMessage(err));
            } finally {
                this.loading = false;
                this.closeModal();
            }
        }
    }
}
</script>