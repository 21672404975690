<template>
    <div>
        <b-modal ref="addModal" title="Add accounts to whitelist">
            <p>{{ 'Enter the information requested below' }}</p>
            <account-finder v-if="showFinder" @select="selectAccount" />
            <div v-else>
                <div class="add-modal__account mt-3">
                    <div>
                        <avatar :name="selectedAccount.first_name" :lastName="selectedAccount.last_name" size="xs" />
                    </div>
                    <div class="add-modal__account-content">
                        <h6 class="mb-0">
                            {{ selectedAccount.first_name }} {{ selectedAccount.last_name }}
                        </h6>
                        <span>{{ selectedAccount.email }}</span>
                    </div>
                    <ui-button v-if="!scope" class="add-modal__action scope-form__action--delete" @click="deselectAccount">
                        <span class="fas fa-trash"></span>
                    </ui-button>
                </div>
                <br>
                <ui-tags
                id="users"
                name="users"
                label="Account List"
                placeholder="Enter email or phone separated by space, comma or semicolon"
                v-model:value="formData.users"
                :tag-validations="tagValidations"
                />
            </div>
            <template #footer>
                <div class="text-end">
                    <ui-button :loading="loading" variant="primary" @click="add">
                        Add
                    </ui-button>
                </div>
                <ui-button variant="secondary" @click="closeModal"> Close </ui-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import UiTags from '@/components/ui/Tags.vue';
import UiInput from '@/components/ui/Input';
import UiButton from '@/components/ui/buttons/Button';
import Avatar from '@/components/ui/Avatar.vue';
import AccountFinder from '@/components/account-finder/AccountFinder.vue';
import { or, email, helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { mapState } from 'vuex';

export default {
    name: 'AddModal',
    components: { UiTags, UiButton, UiInput, AccountFinder, Avatar },
    data() {
        return {
            formData: {
                account: '',
                users: [],
            },
            tagValidations: {
                user: helpers.withMessage('Enter a valid email or phone', or(email, helpers.regex(/^[0-9]{8,12}$/) ) ),
            },
            loading: false,
            selectedAccount: {},
            showFinder: true,
        }
    },
    validations() {
        return {
            formData: {
                account: {
                    required: helpers.withMessage('The account is required.', required),
                    valid: helpers.withMessage('Enter a valid email', email),
                    $autoDirty: true,
                },
                users: {
                    required: helpers.withMessage('The account list is required.', required),
                    $autoDirty: true,
                }
            }
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    computed: {
        ...mapState(['globalFilters']),
    },
    mounted() {
        this.checkGlobalFilters();
    },
    methods: {
        checkGlobalFilters() {
            if (this.globalFilters.account !== 0) {
                this.selectedAccount = this.globalFilters.account;
                this.formData.account = this.globalFilters.account.email;
                this.showFinder = false;
                this.filterAccount = true;
            } else {
                this.selectedAccount = {};
                this.showFinder = true;
                this.filterAccount = false;
            }
        },
        async validate() {
           return await this.v$.$validate();
        },
        openModal() {
            this.$refs.addModal.show();
        },
        closeModal() {
            this.selectedAccount = {};
            this.showFinder = true;
            this.formData.users = [];
            this.formData.account = '';
            this.$refs.addModal.hide();
        },
        async add() {
            try {
                this.loading = true;
                this.v$.$touch();
                if (this.v$.formData.$invalid) {
                    return;
                }
                await this.$axios.post('/_/whitelist/create', this.formData);
                this.$toast.success('Accounts added to Whitelist');
                this.closeModal();
                this.$emit('added');
            } catch (err) {
                this.$toast.error(this.getErrorMessage(err));
            } finally {
                this.loading = false;
            }
        },
        selectAccount(account) {
            this.showFinder = false;
            this.selectedAccount = account;
            this.formData.account = account.email;
        },
        deselectAccount() {
            this.showFinder = true;
            this.selectedAccount = {};
            this.formData.account = '';
        },
    },
}
</script>


<style lang="scss">
.add-modal {

    &__account {
        align-items: center;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        padding: 16px;
    }

    &__account-content {
        width: 100%
    }

    &__account-delete-button {
        align-items: center;
        background-color: transparent;
        border: 1px solid #12B4BA;
        border-radius: 8px;
        color: #12B4BA;
        display: flex;
        flex-shrink: 0;
        gap: 8px;
        height: 32px;
        padding: 8px 16px;

        &:hover {
            background-color: #12B4BA;
            color: $white;
        }
    }

    &__action {
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        color: $ecart-secondary-200;
        display: flex;
        height: 30px;
        justify-content: center;
        transition: 0.15s ease-in-out;
        width: 30px;

        &--delete {
            &:hover {
                background-color: rgba($general-error, 0.1);
                color: $general-error;
            }
        }
    }
}
</style>