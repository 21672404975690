<template>
    <div class="account-finder" v-click-outside="closeDropdown" @click="openDropdown">
        <ui-input
            id="name"
            v-model:value="account"
            name="name"
            placeholder="Search accounts"
            :label="'Search account'"
            :debounce="500"
            @input="searchAccount"
        >
            <template #prepend>
                <span class="fas fa-search"></span>
            </template>
        </ui-input>
        <div v-if="showDropdown" class="account-finder__dropdown">
            <div v-if="loading" class="account-finder__account">
                <b-skeleton type="avatar"></b-skeleton>
                <div class="flex-grow-1">
                    <b-skeleton class="w-50" />
                    <b-skeleton class="w-50" />
                </div>
            </div>
            <template v-else>
                <div v-for="account in accounts" :key="account.email" class="account-finder__account" @click.stop="selectAccount(account)">
                    <div>
                        <avatar :name="account.first_name" :lastName="account.last_name" size="xs" />
                    </div>
                    <div class="account-finder__account-content">
                        <h6 class="mb-0">
                            {{ `${account.first_name} ${account.last_name}` }}
                        </h6>
                        <p class="mb-0 text-muted">
                            {{ account.email }}
                        </p>
                    </div>
                </div>
            </template>
        </div>
    </div> 
</template>

<script>
import Avatar from '@/components/ui/Avatar.vue';
import UiInput from '@/components/ui/Input.vue';
import debounce from 'lodash/debounce';
import axios from 'axios';

export default {
    name: 'AccountFinder',
    components: { Avatar, UiInput },
    data() {
        return {
            selectedAccount: '',
            accounts: [],
            loading: false,
            showDropdown: false,
            account: '',
            cancelTokenSource: null,
        }
    },
    watch: {
        account() {
            this.debounceSearchAccount();
        }
    },
    methods: {
        closeDropdown() {
            this.showDropdown = false;
        },
        openDropdown() {
            if (this.account) {
                this.showDropdown = true;
            }
        },
        selectAccount(account) {
            let formatted_account = account;

            if (typeof account === 'string') {
                formatted_account = { account: account };
            }
            this.$emit('select', formatted_account);
            this.closeDropdown();
        },
        async searchAccount() {
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel('Operation canceled due to new request.');
            }
            this.cancelTokenSource = axios.CancelToken.source();

            this.loading = true;
            try {
                const { data } = await this.$axios.get(`/_/accounts/search?q=${this.account}`, {
                    cancelToken: this.cancelTokenSource.token
                });
                this.accounts = data;
                this.showDropdown = true;
            } catch (error) {
                this.accounts = {};
            } finally {
                this.loading = false;
            }
        },
        debounceSearchAccount: debounce(function () {
            this.searchAccount();
        }, 300),

    }
}
</script>

<style lang="scss">
.account-finder {
    position: relative;

    &__dropdown {
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0 8px 16px rgba(#000000, 0.15);
        left: 0;
        max-height: 250px;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 99;
    }

    &__account {
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        gap: 8px;
        padding: 16px;

        &:hover {
            background-color: #f6f6f6;
        }
    }

    &__account-icon {
        align-items: center;
        background-color: $ecart-primary;
        border-radius: 100%;
        color: $white;
        display: flex;
        font-size: 1rem;
        height: 40px;
        justify-content: center;
        width: 40px;
    }

    &__account-content {
        width: 100%
    }
}
</style>
