<template>
    <div class="whitelist-table">
        <ui-table-skeleton v-if="loading" :fields="fields" />
        <template v-else>
            <div class="row g-2 mb-4">
                <div class="col-md-6 col-lg-3">
                    <ui-input
                    id="search"
                    name="search"
                    label="Search account"
                    type="text"
                    placeholder="Enter keywords"
                    :debounce="500"
                    v-model:value="filters.q"
                    >
                    <template #prepend>
                        <span class="fas fa-search"></span>
                    </template>
                    </ui-input>
                </div>
                <div class="col d-flex justify-content-end">
                    <ui-button icon="fas fa-plus" @click="add" variant="primary" v-if="CAN(['write_whitelist'])"> 
                        Add Account
                    </ui-button>
                </div>
            </div>
            <ui-table :items="whitelist" :fields="fields" :busy="busy">
                <template #cell(account)="data">
                    <span v-if="!data.item.account || Object.keys(data.item.account).length == 0" class="badge bg-light text-dark">
                        No information
                    </span>
                    <span v-else >
                        <ui-link weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.account_id } }" target="_blank">
                            <span class="font-weight-600"> {{ data.item.account.first_name }} {{ data.item.account.last_name || '' }}</span>
                        </ui-link>
                        <div>
                            <ui-link underline weight="regular" :link="'mailto:' + data.item.account.email">
                                {{ data.item.account.email }}
                            </ui-link>
                        </div>
                    </span>
                </template>
                <template #cell(customer)="data">
                    <div v-if="data.item.customer && Object.keys(data.item.customer).length > 0">
                        <ui-link weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.customer.id } }" target="_blank">
                            <span class="font-weight-600"> {{ data.item.customer.first_name }} {{ data.item.customer.last_name || '' }}</span>
                        </ui-link>
                        <div>
                            <ui-link underline weight="regular" :link="'mailto:' + data.item.customer.email">
                                {{ data.item.customer.email }}
                            </ui-link>
                        </div>
                    </div>
                    <div v-else>
                        
                        <span v-b-tooltip="'The account is not registered.'" class="fas fa-question-circle text-gray-500 m-1"></span>
                        <ui-link v-if="data.item.email" underline weight="regular" :link="'mailto:' + data.item.email" class="m-1">
                            <span>{{ data.item.email }}</span>
                        </ui-link>
                        <ui-link v-else-if="data.item.phone" underline weight="regular" :link="`tel:+${data.item.phone}`" class="m-1">
                            {{ data.item.phone }}
                        </ui-link>
                    </div>
                </template>
                <template #cell(createdAt)="data">
                    {{ $dayjs(data.item.created_at).format('LL') }} <br />
                    {{ $dayjs(data.item.created_at).format('LTS') }}
                </template>
                <template #cell(updatedAt)="data">
                    {{ $dayjs(data.item.created_at).format('LL') }} <br />
                    {{ $dayjs(data.item.created_at).format('LTS') }}
                </template>
                <template #cell(actions)="data">
                    <b-dropdown variant="none" no-caret toggle-class="border text-muted">
                        <template #button-content>
                            <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item
                            @click="remove(data.item.id)"
                            >Remove from Whitelist</b-dropdown-item
                        >
                    </b-dropdown>
                </template>
                <template #cellFull="data">
                    <tr v-if="data.item.error">
                        <td class="col-full" :colspan="fields.length">
                            <p class="text-center text-red p-3 mb-0">
                                <span class="fas fa-info-circle"></span>
                                {{ data.item.error }}
                            </p>
                        </td>
                    </tr>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
        <add-modal ref="addModal" @added="getWhitelist"/>
        <remove-modal ref="removeModal" @removed="getWhitelist"/>
    </div>
</template>

<script>
import UiPagination from '@/components/ui/Pagination';
import UiTable from '@/components/ui/Table.vue';
import UiLink from '@/components/ui/Link.vue';
import UiInput from '@/components/ui/Input.vue';
import UiButton from '@/components/ui/buttons/Button';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton.vue';
import AddModal from '@/components/whitelist/AddModal.vue'
import RemoveModal from '@/components/whitelist/RemoveModal.vue';

import table_mixin from '@/mixins/tables.mixin';
import { remove } from 'lodash';

export default {
    name: 'Whitelist',
    components: { UiPagination, UiTable, UiTableSkeleton, UiLink, UiInput, UiButton, AddModal, RemoveModal },
    mixins: [table_mixin],
    data() {
        return {
            whitelist: [],
            fields: [
                { key: 'account', label: 'Account' },
                { key: 'customer', label: 'Customer'},
                { key: 'createdAt', label: 'Created At' },
                { key: 'updatedAt', label: 'Updated At' },
                { key: 'actions', label: 'Actions' }
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
            },
            inititialLoading: true,
            loading: false,
            total_pages: null,
            showDetails: false,
            busy: false,
        }
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this._changeFilters(this.getWhitelist);
            }
        }
    },
    methods: {
        async getWhitelist() {
            try {
                if(this.inititialLoading) {
                    this.loading = true;
                }
                
                this.busy = true;

                const formattedFilters = JSON.parse(JSON.stringify(this.filters));
                const params = {
                    page: formattedFilters.page,
                    limit: formattedFilters.limit,
                    q: formattedFilters.q,
                };

                this._clearFilters(params);
                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/whitelist', { params }); 
                this.whitelist = data.docs;
                this.total_pages = data.pages;
            } catch(err) {
                this.$toast.error(this.getErrorMessage(err));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },
        async remove(id) {
            this.$refs.removeModal.openModal(id);
        },
        add() {
            this.$refs.addModal.openModal();
        }
    }
}
</script>

<style lang="scss">
.whitelist-table {
    .whitelist-table__actions {
        align-items: center;
        display: flex;
        margin-left: au;
    }
}
</style>